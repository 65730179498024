import React from "react";
import SEARCH_ERRORS from "../enums/searchErrors";
import SearchErrorItem from "./SearchErrorItem";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
  errorContainer: {
    margin: theme.spacing.unit,
    padding: theme.spacing.unit * 2
  }
});

const SearchError = ({ error, classes }) => {
  return (
    <Paper className={classes.errorContainer}>
      {error === SEARCH_ERRORS.GPS_PERMISSION_DENIED ? (
        <SearchErrorItem
          primaryText="Die Standortbestimmung ist für die Anwendung blockiert!"
          secondaryText="Sie müssen die Blockierung in ihrem Browser aufheben, um diese Funktion nutzen zu können!"
        />
      ) : error === SEARCH_ERRORS.GPS_POSITION_UNAVAILABLE ||
        error === SEARCH_ERRORS.GPS_TIMEOUT ? (
        <SearchErrorItem
          primaryText="Die Standortbestimmung kann momentan nicht durchgeführt werden!"
          secondaryText="Bitte führen Sie eine manuelle Suche durch und/oder versuchen es später erneut!"
        />
      ) : (
        <SearchErrorItem
          primaryText="Fehler!"
          secondaryText="Bei der Verarbeitung der Anfrage ist ein unbekannter Fehler aufgetreten!"
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(SearchError);
