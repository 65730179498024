import React, { PureComponent } from "react";
import Searchbar from "../components/Searchbar";
import { withStyles } from "@material-ui/core/styles";
import SearchResult from "../components/SearchResult";
import SearchResultItem from "../components/SearchResultItem";
import SearchError from "../components/SearchError";
import AppHeader from "../components/AppHeader";
import SEARCH_ERRORS from "../enums/searchErrors";
import getLocation from "../location";
import queryString from "query-string";
import ContainerDialog from "../components/ContainerDialog";
import Grid from "@material-ui/core/Grid";
import axios from "axios";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit
  }
});

const initialState = {
  searchValue: "",
  isFetching: false,
  searchResult: {
    list: [],
    error: undefined,
    searchType: undefined,
    searchValue: undefined
  },
  dialogItem: {
    item: undefined,
    onClick: undefined,
    headerText: undefined,
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined
  },
  containerItem: undefined,
  mountFinished: false
};

class ContainerMount extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    axios
      .get(
        `/api/Container/getContainerById?containerId=${
          this.props.match.params.containerId
        }`
      )
      .then(response => {
        if (response.data && response.data.home) {
          this.setState({
            containerItem: response.data
          });

          const searchParams = queryString.parse(this.props.location.search);
          if (searchParams.id) {
            this.setState({
              searchValue: searchParams.id
            });

            this.fetchItemsById(searchParams.id);
          } else if (searchParams.gps) {
            this.fetchItemsByLocation();
          }
        } else {
          this.props.history.push("/search");
        }
      })
      .catch(() => {
        this.props.history.push("/search");
      });
  }

  fetchItemsById = id => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    axios
      .get(`/api/Container/getCustomerAddressByValue?searchKey=${id}`)
      .then(response => {
        this.props.history.replace(
          `/mount/${this.props.match.params.containerId}?id=${id}`
        );
        this.setState(state => ({
          searchValue: "",
          isFetching: false,
          searchResult: {
            ...initialState.searchResult,
            list: response.data,
            searchType: "VALUE",
            searchValue: state.searchValue
          }
        }));
      })
      .catch(() => {
        this.setState({
          searchValue: "",
          isFetching: false,
          searchResult: {
            ...initialState.searchResult,
            error: SEARCH_ERRORS.FETCH_FAILED
          }
        });
      });
  };

  fetchItemsByLocation = () => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    getLocation()
      .then(position => {
        if (position.coords) {
          const { latitude, longitude } = position.coords;
          axios
            .get(
              `/api/Container/getCustomerAddressByLocation?latitude=${latitude}&longitude=${longitude}`
            )
            .then(response => {
              this.props.history.replace(
                `/mount/${this.props.match.params.containerId}?gps=1`
              );
              this.setState({
                searchValue: "",
                isFetching: false,
                searchResult: {
                  ...initialState.searchResult,
                  list: response.data,
                  searchType: "GPS"
                }
              });
            })
            .catch(() => {
              this.setState({
                searchValue: "",
                isFetching: false,
                searchResult: {
                  ...initialState.searchResult,
                  error: SEARCH_ERRORS.FETCH_FAILED
                }
              });
            });
        } else {
          this.setState({
            searchValue: "",
            isFetching: false,
            searchResult: {
              ...initialState.searchResult,
              error: SEARCH_ERRORS.GPS_NOT_SUPPORTED
            }
          });
        }
      })
      .catch(error => {
        let gpsError = undefined;
        switch (error.code) {
          case 1:
            gpsError = SEARCH_ERRORS.GPS_PERMISSION_DENIED;
            break;
          case 2:
            gpsError = SEARCH_ERRORS.GPS_POSITION_UNAVAILABLE;
            break;
          case 3:
            gpsError = SEARCH_ERRORS.GPS_TIMEOUT;
            break;
          default:
            gpsError = SEARCH_ERRORS.UNKNOWN;
        }

        this.setState({
          searchValue: "",
          isFetching: false,
          searchResult: {
            ...initialState.searchResult,
            error: gpsError
          }
        });
      });
  };

  mountContainer = (containerId, addressId) => {
    this.setState(state => ({
      dialogItem: {
        ...state.dialogItem,
        errorMessage: undefined,
        isLoading: true
      }
    }));

    if (!this.state.dialogItem.item.hasCoords) {
      getLocation()
        .then(position => {
          if (position.coords) {
            this.mountContainerApiCall(
              containerId,
              addressId,
              position.coords.latitude,
              position.coords.longitude
            );
          } else {
            this.mountContainerApiCall(containerId, addressId, null, null);
          }
        })
        .catch(() => {
          this.mountContainerApiCall(containerId, addressId, null, null);
        });
    } else {
      this.mountContainerApiCall(containerId, addressId, null, null);
    }
  };

  mountContainerApiCall = (containerId, addressId, latitude, longitude) => {
    axios
      .post(
        `/api/Container/mount?locId=${containerId}&cadId=${addressId}&latitude=${latitude}&longitude=${longitude}`
      )
      .then(() => {
        this.setState(state => ({
          dialogItem: {
            ...state.dialogItem,
            isLoading: false,
            successMessage: "Container wurde erfolgreich aufgestellt!"
          },
          mountFinished: true
        }));
      })
      .catch(() => {
        this.setState(state => ({
          dialogItem: {
            ...state.dialogItem,
            isLoading: false,
            errorMessage: "Fehler beim Aufstellen des Containers!"
          }
        }));
      });
  };

  handleSearchFieldChange = value => {
    this.setState({
      searchValue: value
    });
  };

  handleSearchButtonClick = id => {
    this.fetchItemsById(id);
  };

  handleGPSButtonClick = () => {
    this.fetchItemsByLocation();
  };

  handleSearchResultItemClick = id => {
    const item = { ...this.state.searchResult.list.find(i => i.id === id) };
    item.containerId = this.state.containerItem.containerId;
    item.containerType = this.state.containerItem.containerType;

    this.setState(state => ({
      dialogItem: {
        item: item,
        onClick: () => this.mountContainer(state.containerItem.id, id),
        headerText: "Aufstellen am Standort"
      }
    }));
  };

  handleContainerDialogClose = () => {
    if (this.state.mountFinished) {
      this.props.history.push("/search");
    }

    this.setState({
      dialogItem: initialState.dialogItem
    });
  };

  handleAppHeaderBackClick = () => {
    this.props.history.push("/search");
  };

  render() {
    const { containerItem } = this.state;
    const headerTitle = containerItem
      ? `Aufstellen - ${containerItem.containerId} (${
          containerItem.containerType
        })`
      : "Aufstellen";
    return (
      <div className={this.props.classes.root}>
        <AppHeader
          title={headerTitle}
          onBackClick={this.handleAppHeaderBackClick}
        />
        <Grid container>
          <Grid item xs={12}>
            <Searchbar
              searchLabel={"Kunde/Adresse"}
              searchValue={this.state.searchValue}
              placeholder={"min. 3 Zeichen"}
              searchValueMinLength={3}
              isFetching={this.state.isFetching}
              onSearchFieldChange={this.handleSearchFieldChange}
              onSearchButtonClick={() =>
                this.handleSearchButtonClick(this.state.searchValue)
              }
              onGPSButtonClick={this.handleGPSButtonClick}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {this.state.searchResult.error ? (
              <SearchError error={this.state.searchResult.error} />
            ) : (
              <SearchResult
                resultCount={this.state.searchResult.list.length}
                searchValue={this.state.searchResult.searchValue}
                searchType={this.state.searchResult.searchType}
              >
                {this.state.searchResult.list.map(r => (
                  <SearchResultItem
                    key={r.id}
                    id={r.id}
                    name={r.name}
                    name_address={r.name_address}
                    street={r.street}
                    postalcode={r.postalcode}
                    city={r.city}
                    containerId={r.containerId}
                    containerType={r.containerType}
                    home={r.home}
                    onClick={() => this.handleSearchResultItemClick(r.id)}
                    divider={true}
                  />
                ))}
              </SearchResult>
            )}
          </Grid>
        </Grid>
        <ContainerDialog
          dialogItem={this.state.dialogItem}
          onClose={this.handleContainerDialogClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ContainerMount);
