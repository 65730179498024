import React, { PureComponent } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  logo: {
    width: "100%"
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  progressbar: {
    marginTop: theme.spacing.unit * 2
  },
  errorMessage: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
    color: "red"
  }
});

const initialState = {
  username: "",
  password: "",
  isLoading: false,
  message: undefined
};

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      isLoading: true,
      message: initialState.message
    });

    axios
      .post(
        `/api/auth/login?username=${this.state.username}&password=${
          this.state.password
        }`
      )
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("user", response.data.username);
          this.props.history.push("/search");
        }
      })
      .catch(() => {
        this.setState({
          ...initialState,
          message: "Login fehlgeschlagen!"
        });
      });
  };

  handleUsernameChange = e => this.setState({ username: e.target.value });

  handlePasswordChange = e => this.setState({ password: e.target.value });

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <img alt="logo" className={classes.logo} src="logo.jpg" />
          <form
            onSubmit={this.handleSubmit}
            autoComplete="off"
            className={classes.form}
          >
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Benutzer</InputLabel>
              <Input
                id="username"
                name="username"
                value={this.state.username}
                onChange={this.handleUsernameChange}
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Passwort</InputLabel>
              <Input
                id="password"
                name="password"
                value={this.state.password}
                type="password"
                onChange={this.handlePasswordChange}
              />
            </FormControl>
            {this.state.isLoading && (
              <LinearProgress className={classes.progressbar} />
            )}
            {this.state.message && (
              <span className={classes.errorMessage}>{this.state.message}</span>
            )}
            <Button
              disabled={
                !this.state.username ||
                !this.state.password ||
                this.state.isLoading
              }
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Log in
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(Login);
