import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchResultItem from "./SearchResultItem";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = theme => ({
  paper: {
    [theme.breakpoints.only("xs")]: {
      margin: "8px"
    }
  },
  progressbar: {
    marginTop: theme.spacing.unit * 2
  },
  successMessage: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
    color: "green"
  },
  errorMessage: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
    color: "red"
  }
});

const ContainerDialog = ({ classes, dialogItem, onClose }) => {
  const {
    item,
    onClick,
    headerText,
    isLoading,
    successMessage,
    errorMessage
  } = dialogItem;

  if (!item) {
    return "";
  }

  return (
    <Dialog
      open={item !== undefined}
      aria-labelledby="container-dialog-title"
      aria-describedby="container-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="container-dialog-title">{headerText}</DialogTitle>
      <DialogContent>
        <SearchResultItem
          id={item.id}
          name={item.name}
          name_address={item.name_address}
          street={item.street}
          postalcode={item.postalcode}
          city={item.city}
          containerId={item.containerId}
          containerType={item.containerType}
          noPadding={true}
        />
        {isLoading && <LinearProgress className={classes.progressbar} />}
        <DialogContentText id="container-dialog-description">
          <span className={classes.errorMessage}>{errorMessage}</span>
          <span className={classes.successMessage}>{successMessage}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          zurück
        </Button>
        {!successMessage && (
          <Button
            fullWidth
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={onClick}
          >
            {isLoading ? "Bitte warten..." : "Bestätigen"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ContainerDialog);
