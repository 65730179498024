import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  paper: {
    [theme.breakpoints.only("xs")]: {
      margin: "8px"
    }
  },
  progressbar: {
    marginTop: theme.spacing.unit * 2
  },
  errorMessage: {
    display: "block",
    marginTop: theme.spacing.unit * 2,
    color: "red"
  }
});

const ConfirmDialog = ({
  classes,
  isOpen,
  isLoading,
  errorMessage,
  dialogTitle,
  dialogText,
  onClick,
  clickText,
  onClose
}) => {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="logout-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{dialogText}</Typography>
        {isLoading && <LinearProgress className={classes.progressbar} />}
        <DialogContentText id="logout-dialog-description">
          <span className={classes.errorMessage}>{errorMessage}</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          zurück
        </Button>
        <Button
          fullWidth
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          {isLoading ? "Bitte warten..." : clickText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmDialog);
