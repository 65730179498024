import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Redirect, withRouter } from "react-router-dom";
import Login from "./containers/Login";
import ContainerSearch from "./containers/ContainerSearch";
import ContainerMount from "./containers/ContainerMount";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import Grid from "@material-ui/core/Grid";
import { PrivateRoute, AnonymousRoute } from "./routeTypes";
import axios from "axios";
import CustomerSearch from "./containers/CustomerSearch";

class App extends Component {
  componentDidMount() {
    const user = localStorage.getItem("user");
    if (user) {
      axios
        .get("/api/auth")
        .then(response => {
          localStorage.setItem("user", response.data);
        })
        .catch(() => {
          localStorage.removeItem("user");
          this.props.history.push("/login");
        });
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container justify="center">
          <Grid item xs={12}>
            <Switch>
              <AnonymousRoute exact path="/login" component={Login} />
              <PrivateRoute exact path="/search" component={ContainerSearch} />
              <PrivateRoute exact path="/customer" component={CustomerSearch} />
              <PrivateRoute
                exact
                path="/mount/:containerId"
                component={ContainerMount}
              />
              <Redirect to="/search" />
            </Switch>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
