import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Searchbar from "../components/Searchbar";
import { withStyles } from "@material-ui/core/styles";
import SearchResult from "../components/SearchResult";
import SearchResultItem from "../components/SearchResultItem";
import SearchError from "../components/SearchError";
import AppHeader from "../components/AppHeader";
import SEARCH_ERRORS from "../enums/searchErrors";
import getLocation from "../location";
import queryString from "query-string";
import axios from "axios";
import CustomerDialog from "../components/CustomerDialog";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit
  }
});

const initialState = {
  searchValue: "",
  isFetching: false,
  searchResult: {
    list: [],
    error: undefined,
    searchType: undefined,
    searchValue: undefined
  },
  dialogItem: undefined
};

class CustomerSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.searchKey) {
      this.fetchItemsByValue(params.searchKey);
    } else if (params.gps) {
      this.fetchItemsByLocation();
    }
  }

  fetchItemsByValue = searchKey => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    axios
      .get(`/api/Customer/getCustomerByValue?searchKey=${searchKey}`)
      .then(response => {
        this.props.history.replace(`/customer/?searchKey=${searchKey}`);
        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            list: response.data,
            searchType: "VALUE",
            searchValue: searchKey
          }
        });
      })
      .catch(() => {
        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            error: SEARCH_ERRORS.FETCH_FAILED
          }
        });
      });
  };

  fetchItemsByLocation = () => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    getLocation()
      .then(position => {
        if (position.coords) {
          const { latitude, longitude } = position.coords;
          axios
            .get(
              `/api/Customer/getCustomerByLocation?latitude=${latitude}&longitude=${longitude}`
            )
            .then(response => {
              this.props.history.replace("/customer/?gps=1");
              this.setState({
                ...initialState,
                searchResult: {
                  ...initialState.searchResult,
                  list: response.data,
                  searchType: "GPS"
                }
              });
            })
            .catch(() => {
              this.setState({
                ...initialState,
                searchResult: {
                  ...initialState.searchResult,
                  error: SEARCH_ERRORS.FETCH_FAILED
                }
              });
            });
        } else {
          this.setState({
            ...initialState,
            searchResult: {
              ...initialState.searchResult,
              error: SEARCH_ERRORS.GPS_NOT_SUPPORTED
            }
          });
        }
      })
      .catch(error => {
        let gpsError = undefined;
        switch (error.code) {
          case 1:
            gpsError = SEARCH_ERRORS.GPS_PERMISSION_DENIED;
            break;
          case 2:
            gpsError = SEARCH_ERRORS.GPS_POSITION_UNAVAILABLE;
            break;
          case 3:
            gpsError = SEARCH_ERRORS.GPS_TIMEOUT;
            break;
          default:
            gpsError = SEARCH_ERRORS.UNKNOWN;
        }

        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            error: gpsError
          }
        });
      });
  };

  handleSearchFieldChange = value => {
    this.setState({
      searchValue: value
    });
  };

  handleSearchButtonClick = value => {
    this.fetchItemsByValue(value);
  };

  handleGPSButtonClick = () => {
    this.fetchItemsByLocation();
  };

  handleSearchResultItemClick = id => {
    this.setState(state => ({
      dialogItem: state.searchResult.list.find(i => i.id === id)
    }));
  };

  handleContainerDialogClose = () => {
    this.setState({
      dialogItem: initialState.dialogItem
    });
  };

  render() {
    return (
      <div className={this.props.classes.root}>
        <AppHeader title="Kundenstammdaten" />
        <Grid container>
          <Grid item xs={12}>
            <Searchbar
              searchLabel={"Kunde"}
              searchValue={this.state.searchValue}
              placeholder={"min. 3 Zeichen"}
              searchValueMinLength={3}
              isFetching={this.state.isFetching}
              onSearchFieldChange={this.handleSearchFieldChange}
              onSearchButtonClick={() =>
                this.handleSearchButtonClick(this.state.searchValue)
              }
              onGPSButtonClick={this.handleGPSButtonClick}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {this.state.searchResult.error ? (
              <SearchError error={this.state.searchResult.error} />
            ) : (
              <SearchResult
                resultCount={this.state.searchResult.list.length}
                searchValue={this.state.searchResult.searchValue}
                searchType={this.state.searchResult.searchType}
              >
                {this.state.searchResult.list.map(r => (
                  <SearchResultItem
                    key={r.id}
                    id={r.id}
                    name_address={r.name_address}
                    name={r.name}
                    street={r.street}
                    postalcode={r.postalcode}
                    city={r.city}
                    onClick={() => this.handleSearchResultItemClick(r.id)}
                    divider={true}
                  />
                ))}
              </SearchResult>
            )}
          </Grid>
        </Grid>
        <CustomerDialog
          item={this.state.dialogItem}
          onClose={this.handleContainerDialogClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CustomerSearch);
