import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      light: "#ffc246",
      main: "#f16b24",
      dark: "#c56200",
      contrastText: "#fafafa"
    }
  }
});
