import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
  root: {
    padding: 8
  },
  button: {
    height: "100%"
  },
  progressbar: {
    marginTop: theme.spacing.unit * 2
  }
});

const Searchbar = ({ classes, ...props }) => {
  return (
    <div className={classes.root}>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <TextField
            autoFocus={true}
            variant="outlined"
            label={props.searchLabel}
            fullWidth
            value={props.searchValue}
            placeholder={props.placeholder}
            onChange={e => props.onSearchFieldChange(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            disabled={
              !props.searchValue ||
              props.isFetching ||
              props.searchValue.length < props.searchValueMinLength
            }
            onClick={() => props.onSearchButtonClick(props.searchValue)}
          >
            <Icon>search</Icon>
            <Hidden xsDown>&nbsp;Suche</Hidden>
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            fullWidth
            disabled={props.isFetching}
            onClick={() => props.onGPSButtonClick()}
          >
            <Icon>gps_fixed</Icon>
            <Hidden xsDown>&nbsp;GPS</Hidden>
          </Button>
        </Grid>
      </Grid>
      {props.isFetching && <LinearProgress className={classes.progressbar} />}
    </div>
  );
};

export default withStyles(styles)(Searchbar);
