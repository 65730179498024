import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import SearchResultItem from "./SearchResultItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const styles = theme => ({
  optionList: {
    width: "auto"
  },
  subheader: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  link: {
    textDecoration: "none"
  }
});

const ContainerMenu = ({ classes, menuItem, onClose }) => {
  const { item, options } = menuItem;
  const optionList = !item ? (
    ""
  ) : (
    <div className={classes.optionList}>
      <List
        disablePadding
        subheader={
          <ListSubheader className={classes.subheader} component="div">
            Optionen
          </ListSubheader>
        }
      >
        {options.map(option => (
          <ListItem key={option.text} button divider onClick={option.onClick}>
            <ListItemIcon>
              <Icon>{option.icon}</Icon>
            </ListItemIcon>
            <ListItemText primary={option.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );
  return (
    <Drawer anchor="bottom" open={item !== undefined} onClose={onClose}>
      <div tabIndex={0} role="button">
        {item && (
          <SearchResultItem
            id={item.id}
            name={item.name}
            name_address={item.name_address}
            street={item.street}
            postalcode={item.postalcode}
            city={item.city}
            containerId={item.containerId}
            containerType={item.containerType}
            home={item.home}
            onClick={() => {}} // TODO Optional über proptypes
            selected
          />
        )}
        {optionList}
      </div>
    </Drawer>
  );
};

export default withStyles(styles)(ContainerMenu);
