import React from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  subheader: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
});

const SearchResult = React.memo(
  ({ searchType, searchValue, classes, resultCount, children }) => {
    return (
      <List
        subheader={
          searchType !== undefined && (
            <ListSubheader className={classes.subheader} component="div">
              {searchType === "VALUE" &&
                `${resultCount} Treffer für '${searchValue}'`}
              {searchType === "GPS" &&
                `${resultCount} Treffer für aktuellen Standort`}
            </ListSubheader>
          )
        }
      >
        {children}
      </List>
    );
  }
);

export default withStyles(styles)(SearchResult);
