import axios from "axios";

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    switch (error.response.status) {
      case 401:
        localStorage.removeItem("user");
        return;
      default:
        console.log(error.repsonse);
    }

    return Promise.reject(error);
  }
);
