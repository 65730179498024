import React, { PureComponent } from "react";
import Grid from "@material-ui/core/Grid";
import Searchbar from "../components/Searchbar";
import { withStyles } from "@material-ui/core/styles";
import SearchResult from "../components/SearchResult";
import SearchResultItem from "../components/SearchResultItem";
import SearchError from "../components/SearchError";
import ContainerMenu from "../components/ContainerMenu";
import AppHeader from "../components/AppHeader";
import SEARCH_ERRORS from "../enums/searchErrors";
import getLocation from "../location";
import queryString from "query-string";
import ContainerDialog from "../components/ContainerDialog";
import axios from "axios";

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit
  }
});

const initialState = {
  searchValue: "",
  isFetching: false,
  searchResult: {
    list: [],
    error: undefined,
    searchType: undefined,
    searchValue: undefined
  },
  menuItem: {
    item: undefined,
    options: []
  },
  dialogItem: {
    item: undefined,
    onClick: undefined,
    headerText: undefined,
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined
  }
};

class ContainerSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (params.id) {
      this.fetchItemsById(params.id);
    } else if (params.gps) {
      this.fetchItemsByLocation();
    }
  }

  fetchItemsById = id => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    axios
      .get(`/api/Container/getContainersByValue?containerValue=${id}`)
      .then(response => {
        this.props.history.replace(`/search/?id=${id}`);
        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            list: response.data,
            searchType: "VALUE",
            searchValue: id
          }
        });
      })
      .catch(() => {
        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            error: SEARCH_ERRORS.FETCH_FAILED
          }
        });
      });
  };

  fetchItemsByLocation = () => {
    this.setState({
      searchResult: initialState.searchResult,
      isFetching: true
    });

    getLocation()
      .then(position => {
        if (position.coords) {
          const { latitude, longitude } = position.coords;
          axios
            .get(
              `/api/Container/getContainersByLocation?latitude=${latitude}&longitude=${longitude}`
            )
            .then(response => {
              this.props.history.replace("/search/?gps=1");
              this.setState({
                ...initialState,
                searchResult: {
                  ...initialState.searchResult,
                  list: response.data,
                  searchType: "GPS"
                }
              });
            })
            .catch(() => {
              this.setState({
                ...initialState,
                searchResult: {
                  ...initialState.searchResult,
                  error: SEARCH_ERRORS.FETCH_FAILED
                }
              });
            });
        } else {
          this.setState({
            ...initialState,
            searchResult: {
              ...initialState.searchResult,
              error: SEARCH_ERRORS.GPS_NOT_SUPPORTED
            }
          });
        }
      })
      .catch(error => {
        let gpsError = undefined;
        switch (error.code) {
          case 1:
            gpsError = SEARCH_ERRORS.GPS_PERMISSION_DENIED;
            break;
          case 2:
            gpsError = SEARCH_ERRORS.GPS_POSITION_UNAVAILABLE;
            break;
          case 3:
            gpsError = SEARCH_ERRORS.GPS_TIMEOUT;
            break;
          default:
            gpsError = SEARCH_ERRORS.UNKNOWN;
        }

        this.setState({
          ...initialState,
          searchResult: {
            ...initialState.searchResult,
            error: gpsError
          }
        });
      });
  };

  retrieveContainer = id => {
    this.setState(state => ({
      dialogItem: {
        ...state.dialogItem,
        errorMessage: undefined,
        isLoading: true
      }
    }));

    axios
      .post(`/api/Container/pickup?locId=${id}`)
      .then(() => {
        this.setState(state => ({
          ...initialState,
          dialogItem: {
            ...state.dialogItem,
            errorMessage: undefined,
            successMessage: "Buchung erfolgreich!",
            isLoading: false
          }
        }));
      })
      .catch(() => {
        this.setState(state => ({
          dialogItem: {
            ...state.dialogItem,
            errorMessage: "Buchung fehlgeschlagen!",
            isLoading: false
          }
        }));
      });
  };

  emptyingContainer = (id, type, latitude, longitude) => {
    this.setState(state => ({
      dialogItem: {
        ...state.dialogItem,
        errorMessage: undefined,
        isLoading: true
      }
    }));

    axios
      .post(
        `/api/Container/depletion?locId=${id}&depletionType=${type}&latitude=${latitude}&longitude=${longitude}`
      )
      .then(() => {
        this.setState(state => ({
          ...initialState,
          dialogItem: {
            ...state.dialogItem,
            errorMessage: undefined,
            successMessage: "Buchung erfolgreich!",
            isLoading: false
          }
        }));
      })
      .catch(() => {
        this.setState(state => ({
          dialogItem: {
            ...state.dialogItem,
            errorMessage: "Buchung fehlgeschlagen!",
            isLoading: false
          }
        }));
      });
  };

  emptyingContainerOnTheSpot = async id => {
    let latitude = null;
    let longitude = null;

    if (!this.state.dialogItem.item.hasCoords) {
      getLocation()
        .then(position => {
          if (position.coords) {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            this.emptyingContainer(id, 1, latitude, longitude);
          } else {
            this.emptyingContainer(id, 1, latitude, longitude);
          }
        })
        .catch(() => {
          this.emptyingContainer(id, 1, latitude, longitude);
        });
    } else {
      this.emptyingContainer(id, 1, latitude, longitude);
    }
  };

  emptyingContainerHome = id => {
    this.emptyingContainer(id, 2, null, null);
  };

  handleSearchFieldChange = value => {
    this.setState({
      searchValue: value
    });
  };

  handleSearchButtonClick = id => {
    this.fetchItemsById(id);
  };

  handleGPSButtonClick = () => {
    this.fetchItemsByLocation();
  };

  handleSearchResultItemClick = (id, isHome) => {
    let options = [];
    if (isHome) {
      options = options.concat([
        {
          text: "Aufstellen am Zielort",
          icon: "add_location",
          onClick: () => {
            this.props.history.push(`/mount/${id}`);
          }
        }
      ]);
    } else {
      options = options.concat([
        {
          text: "Retour zum Schrottplatz",
          icon: "reply",
          onClick: () => {
            this.handleContainerMenuRetrieveClick(id);
          }
        },
        {
          text: "Entleerung vor Ort",
          icon: "delete",
          onClick: () => {
            this.handleContainerMenuEmptyingOnTheSpotClick(id);
          }
        },
        {
          text: "Entleerung am Schrottplatz",
          icon: "swap_horiz",
          onClick: () => {
            this.handleContainerMenuEmptyingHomeClick(id);
          }
        }
      ]);
    }

    this.setState(state => ({
      menuItem: {
        item: state.searchResult.list.find(i => i.id === id),
        options: options
      }
    }));
  };

  handleContainerMenuClose = () => {
    this.setState({
      menuItem: initialState.menuItem
    });
  };

  handleContainerDialogClose = () => {
    this.setState({
      dialogItem: initialState.dialogItem
    });
  };

  handleContainerMenuRetrieveClick = id => {
    this.setState(state => ({
      dialogItem: {
        item: state.searchResult.list.find(i => i.id === id),
        onClick: () => this.retrieveContainer(id),
        headerText: "Retour zum Schrottplatz"
      },
      menuItem: initialState.menuItem
    }));
  };

  handleContainerMenuEmptyingOnTheSpotClick = id => {
    this.setState(state => ({
      dialogItem: {
        item: state.searchResult.list.find(i => i.id === id),
        onClick: () => this.emptyingContainerOnTheSpot(id),
        headerText: "Entleerung vor Ort"
      },
      menuItem: initialState.menuItem
    }));
  };

  handleContainerMenuEmptyingHomeClick = id => {
    this.setState(state => ({
      dialogItem: {
        item: state.searchResult.list.find(i => i.id === id),
        onClick: () => this.emptyingContainerHome(id),
        headerText: "Entleerung am Schrottplatz"
      },
      menuItem: initialState.menuItem
    }));
  };

  render() {
    return (
      <div className={this.props.classes.root}>
        <AppHeader title="Containersuche" />
        <Grid container>
          <Grid item xs={12}>
            <Searchbar
              searchLabel={"Containernummer"}
              searchValue={this.state.searchValue}
              searchValueMinLength={0}
              isFetching={this.state.isFetching}
              onSearchFieldChange={this.handleSearchFieldChange}
              onSearchButtonClick={() =>
                this.handleSearchButtonClick(this.state.searchValue)
              }
              onGPSButtonClick={this.handleGPSButtonClick}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {this.state.searchResult.error ? (
              <SearchError error={this.state.searchResult.error} />
            ) : (
              <SearchResult
                resultCount={this.state.searchResult.list.length}
                searchValue={this.state.searchResult.searchValue}
                searchType={this.state.searchResult.searchType}
              >
                {this.state.searchResult.list.map(r => (
                  <SearchResultItem
                    key={r.id}
                    id={r.id}
                    name={r.name}
                    name_address={r.name_address}
                    street={r.street}
                    postalcode={r.postalcode}
                    city={r.city}
                    containerId={r.containerId}
                    containerType={r.containerType}
                    home={r.home}
                    onClick={() =>
                      this.handleSearchResultItemClick(r.id, r.home)
                    }
                    divider={true}
                  />
                ))}
              </SearchResult>
            )}
          </Grid>
        </Grid>
        <ContainerMenu
          menuItem={this.state.menuItem}
          onClose={this.handleContainerMenuClose}
        />
        <ContainerDialog
          dialogItem={this.state.dialogItem}
          onClose={this.handleContainerDialogClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ContainerSearch);
