const positionOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};

const getLocation = () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, positionOptions)
    );
  } else {
    return new Promise(resolve => resolve({}));
  }
};

export default getLocation;
