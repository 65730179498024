import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Menu, ChevronLeft } from "@material-ui/icons";
import AppHeaderMenu from "./AppHeaderMenu";

const styles = theme => ({
  title: {
    flexGrow: 1
  },
  titleOnly: {
    paddingLeft: theme.spacing.unit * 2
  },
  toolbarPlaceholder: theme.mixins.toolbar
});

const initialState = {
  menuOpen: false
};

class AppHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  toggleMenu = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen
    }));
  };

  render() {
    const { classes, title, onBackClick } = this.props;
    return (
      <div>
        <AppBar position="fixed" color="primary" elevation={0}>
          <Toolbar disableGutters>
            {onBackClick && (
              <IconButton onClick={onBackClick} color="inherit">
                <ChevronLeft />
              </IconButton>
            )}
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              classes={{
                selected: classes.selectedOverride,
                root: `${classes.title} ${
                  !onBackClick ? classes.titleOnly : ""
                }`
              }}
            >
              {title}
            </Typography>
            <IconButton onClick={this.toggleMenu} color="inherit">
              <Menu />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.toolbarPlaceholder} />
        <AppHeaderMenu
          open={this.state.menuOpen}
          toggleMenu={this.toggleMenu}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AppHeader);
