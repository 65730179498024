import React from "react";
import Typography from "@material-ui/core/Typography";

const SearchErrorItem = ({ primaryText, secondaryText }) => {
  return (
    <>
      <Typography align="center" color="secondary" variant="body1" gutterBottom>
        {primaryText}
      </Typography>
      <Typography align="center">{secondaryText}</Typography>
    </>
  );
};

export default SearchErrorItem;
