const SEARCH_ERRORS = Object.freeze({
  UNKNOWN: 0,
  GPS_PERMISSION_DENIED: 1,
  GPS_POSITION_UNAVAILABLE: 2,
  GPS_TIMEOUT: 3,
  GPS_NOT_SUPPORTED: 4,
  FETCH_FAILED: 5
});

export default SEARCH_ERRORS;
