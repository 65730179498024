import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

const styles = theme => ({
  typographySecondary: {
    lineHeight: 1.6
  },
  containerId: {
    display: "inline-block",
    float: "right",
    width: "45px",
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    textAlign: "center"
  },
  containerIdSelectedOverride: {
    display: "inline-block",
    float: "right",
    width: "45px",
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    textAlign: "center"
  },
  selectedOverride: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: theme.palette.background.default
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  noClick: {
    pointerEvents: "none"
  },
  homeIcon: {
    position: "absolute",
    fontSize: "20px"
  },
  homeDescription: {
    marginLeft: "25px"
  }
});

const SearchResultItem = ({
  classes,
  name,
  name_address,
  containerId,
  street,
  postalcode,
  city,
  containerType,
  home,
  onClick,
  selected,
  noPadding,
  divider
}) => {
  return (
    <ListItem
      button
      divider={divider}
      onClick={onClick}
      selected={selected}
      classes={{
        selected: classes.selectedOverride,
        root: `${!onClick ? classes.noClick : ""} ${
          noPadding ? classes.noPadding : ""
        }`
      }}
    >
      <Grid container>
        <Grid item xs={10} sm={9}>
          {home ? (
            <>
              <Icon className={classes.homeIcon}>home</Icon>
              <Typography
                className={classes.homeDescription}
                gutterBottom={true}
                variant="button"
                color="inherit"
              >
                {name}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                gutterBottom={true}
                inline={true}
                variant="button"
                color="inherit"
              >
                {name}
              </Typography>
              {name_address && (
                <Typography
                  gutterBottom={true}
                  inline={true}
                  variant="overline"
                  color="inherit"
                >
                  &nbsp; {name_address}
                </Typography>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={2} sm={3}>
          <Typography
            className={
              selected
                ? classes.containerIdSelectedOverride
                : classes.containerId
            }
            gutterBottom={true}
            variant="button"
            color="inherit"
            align="right"
          >
            {containerId}
          </Typography>
        </Grid>
        {/* {name_address && (
          <Grid item xs={12}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="subheading"
            >
              {name_address}
            </Typography>
          </Grid>
        )} */}
        <Grid item xs={8} sm={7}>
          <Typography
            className={classes.typographySecondary}
            color="inherit"
            variant="overline"
          >
            {street}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={5}>
          <Typography
            className={classes.typographySecondary}
            color="inherit"
            variant="overline"
            align="right"
          >
            {containerType}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={classes.typographySecondary}
            color="inherit"
            variant="overline"
          >
            {postalcode} {city}
          </Typography>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default withStyles(styles)(SearchResultItem);
