import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Hidden from "@material-ui/core/Hidden";

const styles = theme => ({
  paper: {
    [theme.breakpoints.only("xs")]: {
      margin: "8px"
    }
  },
  typographySecondary: {
    lineHeight: 2
  },
  numberspacing: {
    letterSpacing: "0.3em"
  }
});

const CustomerDialog = ({ classes, item, onClose }) => {
  if (!item) {
    return "";
  }

  return (
    <Dialog
      open={item !== undefined}
      aria-labelledby="customer-dialog-title"
      aria-describedby="customer-dialog-description"
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="customer-dialog-title">
        <>
          <Typography
            gutterBottom={true}
            inline={true}
            variant="button"
            color="inherit"
          >
            {item.name}
          </Typography>
          {item.name_address && (
            <Typography
              gutterBottom={true}
              inline={true}
              variant="overline"
              color="inherit"
            >
              &nbsp; {item.name_address}
            </Typography>
          )}
        </>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              Straße:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              {item.street}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              Postleitzahl:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              {item.postalcode}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              Ort:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              className={classes.typographySecondary}
              color="inherit"
              variant="overline"
            >
              {item.city}
            </Typography>
          </Grid>
          {item.contact && (
            <>
              <Grid item xs={4}>
                <Typography
                  className={classes.typographySecondary}
                  color="inherit"
                  variant="overline"
                >
                  Kontaktperson:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  className={classes.typographySecondary}
                  color="inherit"
                  variant="overline"
                >
                  {item.contact}
                </Typography>
              </Grid>
            </>
          )}
          {item.phone && (
            <>
              <Grid item xs={4}>
                <Typography
                  className={classes.typographySecondary}
                  color="inherit"
                  variant="overline"
                >
                  Telefon:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  className={`${classes.typographySecondary} ${
                    classes.numberspacing
                  }`}
                  color="inherit"
                  variant="overline"
                >
                  {item.phone}
                </Typography>
              </Grid>
            </>
          )}
          {item.gln && (
            <>
              <Grid item xs={4}>
                <Typography
                  className={classes.typographySecondary}
                  color="inherit"
                  variant="overline"
                >
                  GLN-Nummer:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography
                  className={`${classes.typographySecondary} ${
                    classes.numberspacing
                  }`}
                  color="inherit"
                  variant="overline"
                >
                  {item.gln}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" color="primary" onClick={onClose}>
          zurück
        </Button>
        <Button fullWidth variant="contained" color="primary"
          disabled={item.latitude === 0 && item.longitude === 0}
          onClick={() => window.open(`https://maps.google.com/?q=${item.latitude},${item.longitude}`, "_blank")}>
            <Icon>map</Icon>
            <Hidden xsDown>&nbsp;Maps öffnen</Hidden>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(CustomerDialog);
