import React, { PureComponent } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import ConfirmDialog from "./ConfirmDialog";
import axios from "axios";
import { withRouter } from "react-router-dom";

const styles = theme => ({
  menu: {
    justifyContent: "space-between"
  },
  titleOnly: {
    paddingLeft: theme.spacing.unit * 2
  }
});

const initialState = {
  dialogIsOpen: false,
  dialogIsLoading: false,
  dialogErrorMessage: undefined
};

class AppHeaderMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleDialogClick = () => {
    this.setState({
      dialogIsLoading: true
    });

    axios
      .post("/api/auth/logout")
      .then(response => {
        if (response.status === 200) {
          localStorage.removeItem("user");
          this.props.history.push("/login");
        }
      })
      .catch(() => {
        this.setState({
          ...initialState,
          dialogErrorMessage: "Logout fehlgeschlagen!"
        });
      });
  };

  handleDialogClose = () => {
    this.setState({
      ...initialState
    });
  };

  handleLogoutClick = () => {
    this.setState({
      initialState,
      dialogIsOpen: true
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Drawer
        anchor="top"
        open={this.props.open}
        onClose={this.props.toggleMenu}
      >
        <AppBar position={"relative"} color="primary" elevation={0}>
          <Toolbar className={classes.menu} disableGutters>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.titleOnly}
            >
              Menü
            </Typography>
            <IconButton
              onClick={this.props.toggleMenu}
              color="inherit"
              aria-label="Menu"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem
            button
            component={Link}
            to="/search"
            key={1}
            disabled={this.props.match.path === "/search"}
          >
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={"Containersuche"} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/customer"
            key={2}
            disabled={this.props.match.path === "/customer"}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={"Kundenstammdaten"} />
          </ListItem>
          <Divider />
          <ListItem button onClick={this.handleLogoutClick} key={3}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={"Abmelden"} />
          </ListItem>
        </List>
        <ConfirmDialog
          isOpen={this.state.dialogIsOpen}
          dialogTitle="Ausloggen"
          dialogText="Wollen Sie sich wirklich ausloggen?"
          onClose={this.handleDialogClose}
          onClick={this.handleDialogClick}
          clickText="Ausloggen"
          isLoading={this.state.dialogIsLoading}
          errorMessage={this.state.dialogErrorMessage}
        />
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(AppHeaderMenu));
